import React from 'react'

import { evaluations } from '../data/evaluations.yml'

import Typography from '@material-ui/core/Typography'

import 'react-big-calendar/lib/css/react-big-calendar.css'

import Routes from '@root/routes'

import Layout from '@components/layout'
import Evaluations from '@components/evaluations'
import SEO from '@components/seo'
import Page from '@components/Page'

const EvaluationsPage = () => {
  return (
    <Layout breadcrumbs={[{ url: Routes.AGENDA, label: 'Agenda du cours' }]}>
      <SEO title="Agenda du cours" />

      <Page>
        <Typography variant="h1">Agenda du cours</Typography>
        <Evaluations evaluations={evaluations} />
      </Page>
    </Layout>
  )
}

export default EvaluationsPage
