import React, { useMemo } from 'react'

import { Calendar, momentLocalizer } from 'react-big-calendar'
import { Paper, Box, Typography } from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import moment from 'moment'

import './evaluation.css'

Date.prototype.addHours = function (h) {
  const date = new Date(this.valueOf())
  date.setHours(date.getHours() + h)
  return date
}

Date.prototype.removeDays = function (days) {
  const date = new Date(this.valueOf())
  date.setDate(date.getDate() - days)
  return date
}

const localizer = momentLocalizer(moment)

const Evaluations = ({ evaluations }) => {
  const { views, components } = useMemo(() => {
    return {
      components: {
        eventWrapper: (props) => {
          const defaultType = 'class'
          const classes =
            'event-wrapper event-wrapper--' + (props.event.type || defaultType)
          const time = props.event.allDay ? (
            <div
              className="event-time"
              dangerouslySetInnerHTML={{ __html: '&nbsp' }}
            ></div>
          ) : (
            <div className="event-time">
              {props.event.start === props.event.end ? (
                moment(props.event.start).format('HH:mm')
              ) : (
                <>
                  {moment(props.event.start).format('HH:mm')}
                  {' - '}
                  {moment(props.event.end).format('HH:mm')}
                </>
              )}
            </div>
          )

          return (
            <div className={classes}>
              {props.event.link ? (
                <>
                  <a href={props.event.link} target="_blank">
                    <Icon className="event-link">launch</Icon>
                    {time}
                    {props.children}
                  </a>
                </>
              ) : (
                <>
                  {time}
                  {props.children}
                </>
              )}
            </div>
          )
        },
      },
      views: {
        month: true,
        agenda: true,
      },
    }
  }, [])

  console.log(evaluations)
  const calendarEvaluations = evaluations.map((evaluation) => ({
    ...evaluation,
    id: Math.round(Math.random() * 1000),
  }))

  return (
    <>
      <Paper elevation={5}>
        <Box p={3}>
          L'agenda complet de la session qui{' '}
          <strong>remplace le calendrier monPortail.</strong>
        </Box>
      </Paper>
      <br />

      <br />
      <br />

      <Calendar
        components={components}
        localizer={localizer}
        events={calendarEvaluations}
        startAccessor="start"
        endAccessor="end"
        views={views}
        showAllEvents={true}
        messages={{
          date: 'Date',
          time: 'Heure',
          event: 'Événement',
          allDay: 'Toute la journée',
          week: 'Semaine',
          work_week: 'Semaine ouvrable',
          day: 'Jour',
          month: 'Mois',
          previous: 'Précédent',
          next: 'Suivant',
          yesterday: 'Hier',
          tomorrow: 'Demain',
          today: "Aujourd'hui",
          agenda: 'Agenda',
          noEventsInRange: "Il n'y a pas d'événements dans cette période",
          showMore: (total) => `+${total} plus`,
        }}
      />

      <br />
      <br />

      <Paper elevation={2}>
        <Box p={2}>
          Pour en savoir plus sur les types d'événements voir les{' '}
          <a href="/le-cours/horaire-activites">activités</a>.
        </Box>
      </Paper>
      <br />
      <Paper elevation={2}>
        <Box p={2}>
          <Typography variant="h3">Légende</Typography>

          <div className="event-legend event-legend--evaluation">
            Évaluation
          </div>
          <div className="event-legend event-legend--team">
            Cours, Atelier de travail (labo) ou Clinique
          </div>
          {/*<div className="event-legend event-legend--class">*/}
          {/*  Activité synchrone*/}
          {/*</div>*/}
          {/*<div className="event-legend event-legend--help">*/}
          {/*  Clinique (sur rendez-vous)*/}
          {/*</div>*/}
          <div className="event-legend event-legend--holiday">
            Férié / congé
          </div>
          <div className="event-legend event-legend--mep">Date limite</div>
          <div className="event-legend event-legend--suggestion">
            Suggestion d'avancement<sup>*</sup>
          </div>
          <Typography>
            <sup>*</sup> Ce sont de fortes suggestions basées sur notre
            expérience. Il est important de garder un rythme constant afin de ne
            pas accumuler de retard au fil du cours.
          </Typography>
        </Box>
      </Paper>
    </>
  )
}

export default Evaluations
